<template>
  <v-dialog persistent max-width="400" :value="delete_obj.delete_prop">
    <v-card>
      <v-card-text class="py-3 text-center">
        <h2 class="black--text py-3">{{ delete_obj.title }}</h2>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          @click="delete_row()"
          :disabled="delete_loader"
          :loading="delete_loader"
          color="error"
          >
            {{ approve ? approve : "מחיקה"  }}
        
          </v-btn
        >
        <v-btn @click="$emit('close_delete')"
        >
        {{ cancel ? cancel : "ביטול"  }}
      </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["delete_obj", "approve", "cancel"],
  name: "DeleteDialog",
  data() {
    return {
      delete_loader: false,
    };
  },
  methods: {
    delete_row() {
      this.delete_loader = true;
      this.$store
        .dispatch("public__request", {
          config: {
            url: `${this.delete_obj.delete_url}/${this.delete_obj.id}`,
            method: "delete",
          },
        })
        .then((res) => {
          this.delete_loader = false;
          this.$emit("delete_done");
          this.$emit("close_delete");
          
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
  },
};
</script>
